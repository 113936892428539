<template>
  <div class="reviewContainer">
    <div v-if="!isLoading && !noResults">
      <v-card
        :key="reviewType"
        class="mb-6"
        v-for="reviewType in Object.keys(filteredTasksByReviewType)"
      >
        <v-card-title>
          {{ reviewType }}
        </v-card-title>
        <v-card-text>
          <v-expansion-panels v-model="panels[reviewType]">
            <v-expansion-panel
              v-for="data in filteredTasksByReviewType[reviewType]"
              :key="data.uid"
            >
              <v-expansion-panel-header>
              <span>
                {{ data.title }}
              </span>
                <template v-slot:actions>
                  <v-avatar color="secondary" size="30">
                    <span class="white--text">{{ data.tasks.length }}</span>
                  </v-avatar>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <CardsGrid
                  :cols="gridCols"
                  :tasks="data.tasks"
                  :CardComponent="cardComponent"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </div>
    <StateDisplay v-else :loading="isLoading" loading-message="Loading Review Tasks..." />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ReviewTakCard from "@/components/TaskCards/ReviewTakCard";
import CardsGrid from "@/components/CardsGrid";
import {taskStatus} from "@/constants";
import StateDisplay from "@/components/StateDisplay";

export default {
  name: "ReviewTasks",
  components: {StateDisplay, CardsGrid},
  data() {
    return {
      cardComponent: ReviewTakCard,
      tasksByAssignees: [],
      tasksByReviewType: {},
      panels: {},
      gridCols: {
        cols: "12",
        sm: "6",
        md: "6",
        lg: "4",
      },
      isMapping: true,
    };
  },
  computed: {
    ...mapGetters(["reviewTasks", "userUidMap"]),
    tasks() {
      return this.reviewTasks.tasks;
    },
    filteredTasksByReviewType() {
      const computedObject = {};
      for (const key in this.tasksByReviewType) {
        if (this.tasksByReviewType[key].length > 0) {
          computedObject[key] = this.tasksByReviewType[key];
        }
      }
      return computedObject;
    },
    isLoading() {
      return this.isMapping || !!this.reviewTasks.loading;
    },
    noResults() {
      return !this.isLoading && this.reviewTasks.tasks.length === 0;
    }
  },
  mounted() {
    this.mapTasksByAssignees(true);
  },
  watch: {
    tasks: {
      handler() {
        this.mapTasksByAssignees(false);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(["loadTasks"]),
    async mapTasksByAssignees(showLoading) {
      this.isMapping = showLoading
      let reviewTasksByType = {
        [taskStatus.DRAW]: [],
        [taskStatus.TEMPLATING]: [],
        [taskStatus.REVIEW]: [],
      };
      this.reviewTasks.tasks.forEach((task) => {
        if (task.reviewType === taskStatus.DRAW) {
          reviewTasksByType[taskStatus.DRAW] = [
            ...new Set([
              ...reviewTasksByType[task.reviewType],
              task.assignedTo,
            ]),
          ];
        } else if (task.reviewType === taskStatus.TEMPLATING) {
          reviewTasksByType[taskStatus.TEMPLATING] = [
            ...new Set([
              ...reviewTasksByType[task.reviewType],
              task.assignedTo,
            ]),
          ];
        } else if (task.reviewType === taskStatus.REVIEW) {
          reviewTasksByType[taskStatus.REVIEW] = [
            ...new Set([
              ...reviewTasksByType[task.reviewType],
              task.assignedTo,
            ]),
          ];
        }
      });
      for (const reviewType of Object.keys(reviewTasksByType)) {
        reviewTasksByType[reviewType] = reviewTasksByType[reviewType].map((id) => {
          return {
            uid: id,
            title: this.getTitleByUid(id),
            tasks: this.getTasksByAssigneeAndReviewType(id, reviewType),
          };
        })
      }

      this.tasksByReviewType = reviewTasksByType;
      this.isMapping = false
    },
    getTitleByUid(uid) {
      if (!uid) return uid;
      return this.userUidMap[uid] || uid
    },
    getTasksByAssigneeAndReviewType(uid, reviewType) {
      return this.reviewTasks.tasks.filter((task) => {
        return task.assignedTo === uid && task.reviewType === reviewType;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reviewContainer {
  min-height: 500px;
}
</style>
