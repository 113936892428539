<template>
  <MainDialog :show="show">
    <v-card dark>
      <v-card-title>Push Task Back to {{ selectedStatus }}</v-card-title>
      <v-card-text>
        <v-autocomplete v-model="selectedStatus" :items="status" outlined />
        <v-textarea label="Reason" dark outlined v-model="reason" />
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="onClose">Cancel</v-btn>
        <v-spacer />
        <v-btn
          @click="pushBack"
          :disabled="loading"
          :loading="loading"
          color="success"
          >Push Back</v-btn
        >
      </v-card-actions>
    </v-card>
  </MainDialog>
</template>

<script>
import { mapActions } from "vuex";
import MainDialog from "@/components/MainDialog";

export default {
  name: "PushTaskBackDialog",
  components: { MainDialog },
  data() {
    return {
      reason: "",
      loading: false,
      selectedStatus: "",
    };
  },
  created() {
    this.selectedStatus = this.status[0];
  },
  props: {
    show: {
      default: false,
    },
    onClose: {
      required: true,
    },
    onPushBack: {
      require: false,
    },
    status: {
      required: true,
      type: Array,
    },
    taskUid: {
      required: true,
      type: String,
    },
  },
  methods: {
    ...mapActions(["pushTaskBack"]),
    async pushBack() {
      this.loading = true;
      this.pushTaskBack({
        taskUid: this.taskUid,
        status: this.selectedStatus,
        reason: this.reason,
        callback: () => {
          this.loading = false;
          this.onClose();
          if (this.onPushBack) {
            this.onPushBack();
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
