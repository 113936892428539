import { render, staticRenderFns } from "./RatingCardAdmin.vue?vue&type=template&id=035b44c4&scoped=true"
import script from "./RatingCardAdmin.vue?vue&type=script&lang=js"
export * from "./RatingCardAdmin.vue?vue&type=script&lang=js"
import style0 from "./RatingCardAdmin.vue?vue&type=style&index=0&id=035b44c4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "035b44c4",
  null
  
)

export default component.exports