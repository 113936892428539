var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_c('a',{staticClass:"mr-3",on:{"click":() => _vm.openOrder(_vm.rating.orderId)}},[_vm._v(_vm._s(_vm.rating.orderName))]),_c('v-icon',{attrs:{"color":_vm.rating.satisfied ? 'success' : 'error'}},[_vm._v(_vm._s(_vm.rating.satisfied ? "mdi-thumb-up" : "mdi-thumb-down"))]),_c('v-spacer'),_c('OrderNoteTooltip',{attrs:{"task":_vm.rating.task}})],1),_c('v-card-subtitle',[_vm._v(" by "+_vm._s(_vm.artistName)+", "+_vm._s(_vm.timeAgo)+" ")]),_c('v-card-text',{staticClass:"card-content"},[(!_vm.expandComment)?_c('v-img',{attrs:{"contain":"","height":"300px","src":_vm.rating.mockupImage || _vm.rating.resultThumbnailUrl}}):_vm._e(),_c('div',{staticClass:"rating-comment",class:{ 'rating-comment--full': _vm.expandComment },on:{"click":() => {
          _vm.expandComment = !_vm.expandComment;
        }}},[_vm._v(" "+_vm._s(_vm.rating.comment)+" ")])],1),_c('v-card-actions',[(!_vm.rating.satisfied)?[_c('v-tooltip',{attrs:{"open-delay":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"success","outlined":""},on:{"click":_vm.openMailDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email")])],1)]}}],null,false,4236685047)},[_c('span',[_vm._v("Contact Customer")])]),_c('v-tooltip',{attrs:{"open-delay":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"small":"","color":"success","outlined":""},on:{"click":_vm.searchOrderInMails}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email-search-outline")])],1)]}}],null,false,3986348686)},[_c('span',[_vm._v("Search in mails")])]),_c('v-tooltip',{attrs:{"color":"error","open-delay":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"disabled":_vm.pushedBack,"small":"","color":"error"},on:{"click":_vm.openPushBackDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-backspace")])],1)]}}],null,false,1494465964)},[_c('span',[_vm._v("Push Task Back")])]),_c('v-tooltip',{attrs:{"open-delay":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"small":"","color":"warning","outlined":""},on:{"click":_vm.openReassignDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-reactivate")])],1)]}}],null,false,418860784)},[_c('span',[_vm._v("Reassign Task (To Pay)")])]),_c('v-spacer')]:_vm._e(),_c('v-btn',{attrs:{"width":_vm.rating.satisfied ? '100%' : '',"disabled":_vm.rating.shared || _vm.isOwnFeedback,"small":"","color":"success"},on:{"click":() => {
          _vm.showShareDialog = true;
        }}},[_vm._v(_vm._s(_vm.ratingBtnText))])],2),(_vm.showPushTaskBackDialog)?_c('PushTaskBackDialog',{attrs:{"status":['DRAW', 'TEMPLATING'],"show":_vm.showPushTaskBackDialog,"task-uid":_vm.rating.taskUid,"on-close":_vm.closePushBackDialog,"on-push-back":_vm.markAsPushedBack}}):_vm._e(),(_vm.showReassignDialog)?_c('ReassignDialog',{attrs:{"show":_vm.showReassignDialog,"task":_vm.rating.task,"on-close":_vm.closeReassignDialog,"persistent":false}}):_vm._e(),(_vm.showMailDialog)?_c('OrderMailDialog',{attrs:{"v2":"","show":_vm.showMailDialog,"on-close":_vm.closeMailDialog,"orderId":_vm.rating.orderId,"mail-category":"RATING","wait-for-sent-success":false}}):_vm._e(),_c('ShareRatingDialog',{attrs:{"close":() => {
        _vm.showShareDialog = false;
      },"show":_vm.showShareDialog,"rating":_vm.rating},on:{"shared":_vm.onShared}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }