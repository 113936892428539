<template>
<MainDialog :on-close="onClose" :show="show" :persistent="persistent">
  <v-card>
    <v-card-title>Reassign Task</v-card-title>
    <v-card-subtitle>Task Rating is deleted and selected artist will be paid again.</v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            height="56"
            v-model="taskCopy.assignedTo"
            :items="userNameIdMap"
            dense
            item-text="username"
            item-value="uid"
            outlined
            label="Reassign To"
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <v-img :src="taskCopy.image" />
        </v-col>
        <v-col cols="4">
          <v-text-field
            outlined
            hide-details
            label="Original File 1"
            v-model="taskCopy.image"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn
            :loading="isUploading === 'image'"
            @click="handleFileImport('image', 'thumb')"
            height="56px"
            width="100%"
          >Upload
          </v-btn>
        </v-col>
      </v-row>
      <input
        ref="uploader"
        style="display: none"
        type="file"
        @change="onFileChanged"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn :loading="isReassigning" :disabled="isReassigning" color="error" @click="reassignTask">Reassign</v-btn>
    </v-card-actions>
  </v-card>
</MainDialog>
</template>

<script>
import MainDialog from "@/components/MainDialog";
import {mapGetters} from "vuex";
import {v4 as uuidv4} from "uuid";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import {EventBus, events} from "../../../event-bus";
import {currentMonthYear, getImageUrl} from "@/utils";
import _ from "lodash";
import {doc, setDoc} from "firebase/firestore";
import {db} from "../../../fb";
import {taskStatus} from "@/constants";
export default {
  name: "ReassignDialog",
  components: {MainDialog},
  data() {
    return {
      isUploading: null,
      selectedFieldName: null,
      selectedFieldNameThumb: null,
      taskCopy: null,
      isReassigning: false
    }
  },
  props: {
    show: Boolean,
    persistent: Boolean,
    task: Object,
    onClose: Function
  },
  computed: {
    ...mapGetters(["userNameIdMap"])
  },
  created() {
    this.taskCopy = _.cloneDeep(this.task);
  },
  methods: {
    handleFileImport(fieldName, fieldNameThumb) {
      this.selectedFieldName = fieldName;
      this.selectedFieldNameThumb = fieldNameThumb;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          setTimeout(() => {
            this.selectedFieldName = null;
          }, 500);
        },
        {once: true}
      );

      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      const id = uuidv4();
      this.isUploading = this.selectedFieldName;
      const storage = getStorage();

      // 1. Extract the actual file type from the uploaded file
      let uploadedFileType = e.target.files[0].type.split("/")[1];

      if (e.target.files[0].type.split("/")[1] === 'jpeg') {
        uploadedFileType = 'jpg'
      }

      const fileType =
        this.selectedFieldName === "finalTemplate" ? "jpg" : uploadedFileType; // All templates going to MyPoster needs to be jpg


      // 2. Check if the uploaded file type matches the expected file type
      if (!!fileType && uploadedFileType !== fileType) {
        EventBus.$emit(events.SNACKBAR, {
          message: "Templates must have JPG format",
          type: "error",
        });
        this.isUploading = null;
        return; // Stop further processing
      }

      const storageRef = ref(
        storage,
        `/customTaskImages/${currentMonthYear}/${this.task.uid}_${id}.${fileType}`
      );
      const uploadResponse = await uploadBytes(storageRef, e.target.files[0]);
      this.taskCopy[this.isUploading] = await getDownloadURL(
        uploadResponse.ref
      );
      if (this.selectedFieldNameThumb) {
        this.taskCopy[this.selectedFieldNameThumb] = getImageUrl(
          `customTaskImages/${currentMonthYear}/thumbnails/${this.task.uid}_${id}_500x500.png`
        );
      }
      this.isUploading = null;
    },
    async reassignTask() {
      this.isReassigning = true;
      await setDoc(
        doc(db, `tasks`, this.task.uid),
        {
          ...this.taskCopy,
          isRevision: "",
          feedback: null,
          status: taskStatus.DRAW
        },
        {
          merge: true,
        }
      );
      this.isReassigning = false;
      this.onClose()
    }
  }
}
</script>

<style scoped>

</style>
